import {React, useEffect} from 'react';

import './HomeStyles.scss';

import TemplateDefault from '../../Templates/TemplateDefault';

import Teen from '../../../Media/Site/10.webp';
import Years from '../../../Media/Site/anos.webp';
import Sonora from '../../../Media/Site/sonora.webp';

import Aos from 'aos';
import 'aos/dist/aos.css';

import Form from '../../../../Components/Form';

const Home = () => {

  useEffect(() => {
    Aos.init({
      duration: 1200,
      once: true,
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <TemplateDefault>
      <div className="home">
        <div className="home-clouds"></div>
        <div className="home-wrap">
          {/* Title */}
          <div className="home-wrap-title">
            <picture data-aos="fade-up" data-aos-delay="100" className='teen'>
              <source srcSet={Teen} type="image/webp" />
              <img src={Teen} alt="Teen" />
            </picture>
            <picture data-aos="fade-up" data-aos-delay="200" className='years'>
              <source srcSet={Years} type="image/webp" />
              <img src={Years} alt="Years" />
            </picture>
            <picture data-aos="fade-up" data-aos-delay="300" className='sonora'>
              <source srcSet={Sonora} type="image/webp" />
              <img src={Sonora} alt="Sonora" />
            </picture>
          </div>
          {/* Form */}
          <div className="home-wrap-form relative z-10" data-aos="zoom-in" data-aos-delay="400">
            <div className="home-wrap-form-title">
              <h1 className='text-center block text-[#2F284D] mt-24 mb-[30px]'>INSIRA SEU <b className='font-medium'>E-MAIL</b> E CONCORRA!</h1>
            </div>
            <div className="home-wrap-form-input">
              <Form />      
            </div>
            </div>
        </div>
      </div>
    </TemplateDefault>
  );
}

export default Home;