import axios from "axios";

const Client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST || 'http://localhost',
})

export function Response(response) {
  if (response.status === 200) {
    return {success: true, code: 200, data: response.data};
  }

  if (response.status === 422) {
    return {success: false, code: 422, data: response.data};
  }

  return {success: false, code: response.status, data: response.data};
}

export default Client;
