import NotFound from './Resources/Views/Site/NotFound';
import Home from './Resources/Views/Site/Home';
import Prize from './Resources/Views/Site/Prize';
import Health from './Resources/Views/Core/Health';
import LoadingIcons from 'react-loading-icons';

import { useContext } from 'react';
import { LoadingContext } from './Shared/LoadingContext';
import { Routes, Route } from 'react-router-dom';

function App() {
  const { loadingState } = useContext(LoadingContext);
  return (
    <>
      {loadingState && (
        <div className="loading">
          <div className="ico">
            <LoadingIcons.SpinningCircles />
          </div>
        </div>
      )}
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/sorteio" element={<Prize />} />
        <Route path="/sonora-health" element={<Health />} />
      </Routes>
    </>
  );
}

export default App;
