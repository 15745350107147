import Client, {Response} from "./Client";

export async function Login(email) {
  try {
    const response = await Client.post('login', {email: email});
    return Response(response);
  } catch (error) {
    return Response(error.response);
  }
}
