import React, { useState } from 'react';
import Button from '../../Components/Button';
import InputText from '../../Components/InputText';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import {Login} from "../../Backend/Auth";

const validPrefixes = ['rubens.monteiro', 'prefix2', 'prefix3']; 

const Form = () => {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const emailPrefix = data.email.split('@')[0];

    if (emailPrefix === "") {
      setError('email', {
        type: 'manual',
        message: 'Adicione seu nome de usuário',
      });
      return;
    }

    const email = data.email + '@agenciasonora.com.br';

    const response = await Login(email);

    if(response.success) {
      setSuccess(true);
      localStorage.setItem('id', response.data.id);
      localStorage.setItem('name', response.data.name);
      localStorage.setItem('prize', response.data.prize);
      navigate("/sorteio");
    } else {
      setError('email', {
        type: 'manual',
        message: 'Usuário não encontrado',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='max-w-[290px] block m-auto'>
      <Controller
        name='email'
        control={control}
        defaultValue=''
        render={({ field }) => (
          <>
            <InputText {...field} error={errors.email?.message} />
          </>
        )}
      />
      <small className='text-center block text-lg font-light text-[#2F284D]'>@agenciasonora.com.br</small>
      {errors.email && (
        <p className="error text-center">{errors.email.message}</p>
      )}
      <Button
        type="submit"
        variant="contained w-[290px] absolute mt-[30px]"
        text="PARTICIPAR"
      />
      <div className='mt-[100px] block relative max-w-max m-auto'>
      <svg xmlns="http://www.w3.org/2000/svg" width="60.268" height="41.617" viewBox="0 0 60.268 41.617">
        <g id="logo_sonora" transform="translate(-378.365 378.571)">
          <g id="Group_8284" data-name="Group 8284" transform="translate(378.365 -378.571)">
            <path id="Path_278" data-name="Path 278" d="M30.192,43.16a2.23,2.23,0,0,1-2.231,2.228H27.6a2.23,2.23,0,0,1-2.234-2.228V13.472A2.23,2.23,0,0,1,27.6,11.247h.359a2.23,2.23,0,0,1,2.231,2.225Z" transform="translate(-6.512 -3.771)" fill="#8d79b4"/>
            <path id="Path_279" data-name="Path 279" d="M2.229,31.86h7.6V25.468a2.23,2.23,0,0,1,2.231-2.23h.36a2.231,2.231,0,0,1,2.231,2.23v8.985a2.178,2.178,0,0,1-.116.577,2.142,2.142,0,0,1-.062.288c-.019.041-.059.076-.079.12a2.206,2.206,0,0,1-1.971,1.243H2.229A2.229,2.229,0,0,1,0,34.449v-.361A2.229,2.229,0,0,1,2.229,31.86" transform="translate(0 -6.849)" fill="#2e294c"/>
            <path id="Path_280" data-name="Path 280" d="M73.789,30.391h-7.6V19.753a2.23,2.23,0,0,0-2.23-2.23H63.6a2.232,2.232,0,0,0-2.233,2.23V32.981h0a2.184,2.184,0,0,0,.117.579,1.92,1.92,0,0,0,.061.29c.02.039.059.072.08.116a2.2,2.2,0,0,0,1.971,1.245h10.2a2.23,2.23,0,0,0,2.231-2.231v-.362a2.228,2.228,0,0,0-2.231-2.228" transform="translate(-15.751 -5.382)" fill="#ff5f3e"/>
            <path id="Path_281" data-name="Path 281" d="M42.149,39.369A2.233,2.233,0,0,1,39.915,41.6h-.361a2.229,2.229,0,0,1-2.23-2.234V3.421a2.231,2.231,0,0,1,2.23-2.232h.361a2.234,2.234,0,0,1,2.234,2.232Z" transform="translate(-9.581 -1.189)" fill="#2e294c"/>
            <rect id="Rectangle_28" data-name="Rectangle 28" width="4.822" height="28.323" rx="2.411" transform="translate(36.629 7.463)" fill="#2e294c"/>
          </g>
        </g>
      </svg>
      </div>
    </form>
  );
};

export default Form;
