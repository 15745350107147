import React, { useEffect, useRef, useState } from 'react';

const ResponsiveText = ({ children, minFontSize = 18 }) => {
  const textRef = useRef(null);
  const [fontSize, setFontSize] = useState(20); // Tamanho inicial da fonte
  const [fontExtraSize, setFontExtraSize] = useState(8); // Tamanho inicial da fonte

  useEffect(() => {
    const resizeFont = () => {
      let currentFontSize = fontSize;
      while (textRef.current && textRef.current.scrollHeight > textRef.current.offsetHeight && currentFontSize > minFontSize) {
        currentFontSize--;
        textRef.current.style.fontSize = `${currentFontSize}px`;
      }
      if (currentFontSize === minFontSize && textRef.current.scrollHeight > textRef.current.offsetHeight) {
        textRef.current.className = 'big-text';
        setFontExtraSize(0);
      }
    };

    resizeFont();
  }, [children, fontSize, minFontSize]); // Incluir minFontSize nas dependências do useEffect

  return (
    <div className="" ref={textRef} style={{ fontSize: `${fontSize + fontExtraSize}px`, overflow: 'hidden' }}>
      {children}
    </div>
  );
};

export default ResponsiveText;
