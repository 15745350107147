import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './app';
import './Styles/main.scss';

import { BrowserRouter } from 'react-router-dom';
import { LoadingProvider } from './Shared/LoadingContext';


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <LoadingProvider>
          <App />
      </LoadingProvider>
    </BrowserRouter>
  </React.StrictMode>
);