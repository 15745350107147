import { React, useEffect, useState } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { Howl } from "howler";
import { Textfit } from "react-textfit";
import "./PrizeStyles.scss";

import HandClick from "../../../Media/Site/hand-click.svg";
import Balloon from "../../../Media/Site/balloon.webp";
import BalloonHead from "../../../Media/Site/balloon-part-head.webp";
import BallongFooter from "../../../Media/Site/balloon-part-bottom.webp";

import TemplateDefault from "../../Templates/TemplateDefault";

import Aos from "aos";
import "aos/dist/aos.css";
import ResponsiveText from "../../../../Components/ResponsiveText";

const Prize = () => {
  const [isExploded, setIsExploded] = useState(false);
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    Aos.init({
      duration: 800,
      once: true,
      easing: "ease-in-out",
    });
  }, []);

  const handleBallonClick = () => {
    setIsExploded(true);
    if (!isSoundPlaying) {
      try {
        const sound = new Howl({
          src: [process.env.PUBLIC_URL + "/media/sound/balloonpop-83760.mp3"],
        });

        sound.play();
        sound.on("end", () => {
          setIsSoundPlaying(false);
        });
  
        setIsSoundPlaying(true);
      } catch (error) {
        console.error('Error playing sound:', error);
        setIsSoundPlaying(false);
      }
    }
  };
  
  return (
    <TemplateDefault>
      <section className="prize pt-5">
        {!isExploded && (
          <>
            <div className="container m-auto">
              <h1 className="text-center" data-aos="fade-up">
                <b className="font-medium">ESTOURE O BALÃO</b> E <br />
                DESCUBRA <b className="font-medium">SEU PRÊMIO</b>
              </h1>
              <h2
                className="text-center mt-[15px]"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <b className="font-medium">CLIQUE NO BALÃO</b>
              </h2>
            </div>
            <div className="container m-auto">
              <figure
                className="flex justify-center mt-[7px]"
                role="ShowWhereToClick"
              >
                <img src={HandClick} alt="HandClick" className="block m-auto" />
              </figure>
              <button className="m-auto block" onClick={handleBallonClick}>
                <figure
                  className="flex justify-center mt-[30px]"
                  role="BallonToExplode"
                >
                  <img
                    width={290}
                    src={Balloon}
                    alt="Balloon"
                    className="block m-auto"
                    data-aos="zoom-in-up"
                    data-aos-duration="2000"
                  />
                </figure>
              </button>
            </div>
          </>
        )}
        {isExploded && (
          <>
            <Confetti width={width} height={height} />
            <div className="container m-auto" role="isExploded">
              <img
                width={268}
                src={BalloonHead}
                alt="BalloonHead"
                className="block m-auto"
                data-aos="zoom-out-up"
                data-aos-duration="500"
              />
              <div
                className="bg-[#2F284D] h-[320px] w-[290px] m-auto rounded-[40px] flex justify-center items-center prize-win"
                data-aos="flip-left"
              >
                <div className="px-10">
                  <p className="text-center mb-[6px] font-medium text-[#FF5F3E] text-[40px] leading-[40px]">
                    <span className="font-medium text-white text-[30px]">
                      Parabéns,
                    </span>{" "}
                    <br />
                    { localStorage.getItem('name') }!
                  </p>

                  <ResponsiveText>
                      <p dangerouslySetInnerHTML={{ __html: localStorage.getItem('prize')}} className="text-center mb-[27px] px-3 w-[290px] h-[125px] text-win mt-5 leading-8 text-white"></p>
                  </ResponsiveText>
                  <small className="text-center block text-[#8D79B4] text-[18px]">
                    Aguarde instruções.
                  </small>
                </div>
              </div>
              <img
                width={268}
                src={BallongFooter}
                alt="BallongFooter"
                className="block m-auto "
                data-aos="zoom-out-down"
                data-aos-duration="500"
              />
              <div className="prize-win-box" data-aos="fade-up">
                Não saia desta tela.
              </div>
            </div>
          </>
        )}
      </section>
    </TemplateDefault>
  );
};

export default Prize;
