import Header from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';

const TemplateDefault = ({ children, theme }) => {
  return (
    <>
      <Header data-theme={theme} />
        <main role="main">
          {children}
        </main>
      <Footer data-theme={theme} />
    </>
  );
}

export default TemplateDefault;